import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';

const initialState = {
  fieldAgents: [],
  isLoading: false,
  isLoadingBanks: false,
  isSubmitting: false,
  attachmentUrl: null,
  isUploadingFiles: false,
  banks: [],
  error: '',
  closeDepositFormModal: false,
};

export const cashDepositFormSlice = createSlice({
  name: 'cashDepositForm',
  initialState,
  reducers: {
    setFieldAgents: (state, { payload }) => {
      const { fieldAgents } = payload;
      state.isLoading = false;
      state.fieldAgents = fieldAgents;
    },
    setBanks: (state, { payload }) => {
      const { banks } = payload;
      state.isLoadingBanks = false;
      state.banks = banks;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setLoadingBanks: (state) => {
      state.error = '';
      state.isLoadingBanks = true;
    },
    setSubmitting: (state) => {
      state.error = '';
      state.isSubmitting = true;
    },
    stopLoading: (state) => {
      state.error = '';
      state.isLoading = false;
      state.isSubmitting = false;
      state.isLoadingBanks = false;
    },
    setUploadFile: (state, { payload }) => {
      state.attachmentUrl = payload.url;
      state.isUploadingFiles = false;
    },
    removeUploadFile: (state) => {
      console.log('>>> Removing uploaded file');
      state.attachmentUrl = null;
    },
    setUploadingFiles: (state) => {
      state.error = '';
      state.isUploadingFiles = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      stopLoading();
    },
    closeDepositFormModal: (state) => {
      state.closeDepositFormModal = true;
    },
    clearState: (state) => {
      state.fieldAgents = [];
      state.isLoading = false;
      state.isLoadingBanks = false;
      state.isSubmitting = false;
      state.attachmentUrl = null;
      state.isUploadingFiles = false;
      state.banks = [];
      state.error = '';
      state.closeDepositFormModal = false;
      console.log('>>> State cleared');
    },
  },
});

export const fieldAgentsFetchUsingApi = (filters) =>
  getApi().get('/api/field-agents', { params: filters });

export const fetchBanksFromNetwork = (filters) =>
  getApi().get('/api/float-cash/accounts', { params: filters });

export const fieldAgentsFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  fieldAgentsFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setFieldAgents({
          fieldAgents: response.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const submitForm = (formData) => (dispatch) => {
  dispatch(setSubmitting(true));
  getApi()
    .post('/api/float-cash/transaction', formData)
    .then((res) => {
      stopLoading();
      console.log('>>> Result ok. Closing form modal');
      dispatch(closeDepositFormModal());
      dispatch(push('/float-cash/cash'));
      console.log('>>> All closed');
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(stopLoading()));
};

export const uploadAttachmentsFile = (file) => (dispatch) => {
  dispatch(setUploadingFiles(true));
  getApi()
    .post('/api/storage/upload', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      dispatch(setUploadFile(res.data));
    })
    .catch((err) => dispatch(setError(getApiError(err))))
    .finally(() => dispatch(stopLoading()));
};

export const fetchBanks = (filters) => (dispatch) => {
  dispatch(setLoadingBanks(true));
  fetchBanksFromNetwork(filters)
    .then((response) =>
      dispatch(
        setBanks({
          banks: response.data?.data || [],
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const {
  setError,
  setLoading,
  setLoadingBanks,
  setFieldAgents,
  setSubmitting,
  setUploadingFiles,
  removeUploadFile,
  setUploadFile,
  stopLoading,
  setBanks,
  clearState,
  closeDepositFormModal,
} = cashDepositFormSlice.actions;

export const cashDepositFormReducer = cashDepositFormSlice.reducer;
