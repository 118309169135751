export const updateInList = (objs, listObjs, field) => {
  listObjs = [...listObjs];
  const keys = new Map(objs.map((obj) => [obj[field], obj]));
  for (let index = 0; index < listObjs.length; index++) {
    const listObj = listObjs[index];
    if (keys.has(listObj[field])) {
      listObjs[index] = keys.get(listObj[field]);
      keys.delete(listObj[field]);
    }
  }
  Array.from(keys.entries()).forEach(([key, obj]) => listObjs.push(obj));
  return listObjs;
};

export const makeUnique = (items, keyGetter) => {
  const set = new Set();
  const result = [];
  for (const item of items) {
    const key = keyGetter(item);
    if (!set.has(key)) {
      result.push(item);
      set.add(key);
    }
  }
  // console.log('RXD:tools:makeUnique', { items, result });
  return result;
};

export const isNotEmpty = (value) => Array.isArray(value) && value.length > 0;
