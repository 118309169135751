/** Feature flags to disable / enable different functionality in different environments */
export const FEATURES = Object.freeze({
  AGENT: 'agent',
  AGENT_COMPARE: 'agent:compare',
  AGENT_COMPLIANCE: 'agent:compliance',
  AGENT_CONFIGURATION: 'agent:configuration',
  AGENT_EXPENSE: 'agent:expense',
  AGENT_EXPERIMENTATION: 'agent:experimentation',
  AGENT_EXTERNAL_ID: 'agent:external-id',
  AGENT_KNOWLEDGE_CENTER: 'agent:knowledge-center',
  AGENT_PIN: 'agent:pin',
  AGENT_SHOW_ACTIVE: 'agent:show-active',
  AGENT_TRADING_NAME: 'agent:trading-name',
  AGENT_VERIFICATION: 'agent:verification',
  CUSTOMER_REGISTRATION: 'customer_registration',
  FIELD_AGENT: 'field-agent',
  FIELD_AGENT_COMMISSION: 'field-agent:commission',
  FIELD_AGENT_EXPENSE: 'field-agent:expense',
  FIELD_AGENT_TERRITORY: 'field-agent:territory',
  FLOAT_CONTROL: 'float-control',
  FLOAT_EXCHANGE: 'float-exchange',
  LOAN: 'loan',
  NOTIFICATION_CENTER: 'notification-center',
  REPORT: 'report',
  TRANSACTION: 'transaction',
  TRANSACTION_AIRTIME: 'transaction:airtime',
  TRANSACTION_BILLPAY: 'transaction:billpay',
  TRANSACTION_BILLPAY_UG: 'transaction:billpay-ug',
  TRANSACTION_BUNDLE: 'transaction:bundle',
  TRANSACTION_CASH_IN: 'transaction:cash-in',
  TRANSACTION_CASH_OUT: 'transaction:cash-out',
  TRANSACTION_EXTERNAL_ID: 'transaction:external-id',
  TRANSACTION_SHEET: 'transaction:sheet',
  TRANSACTION_TELCO: 'transaction:telco',
  TRANSACTION_TRANSFER: 'transaction:transfer',
  USER: 'user',
  USSD: 'ussd',
  WALLET: 'wallet',
});
const oparetaFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPARE,
  FEATURES.AGENT_EXPENSE,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_PIN,
  FEATURES.AGENT_VERIFICATION,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_EXPENSE,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.FLOAT_EXCHANGE,
  FEATURES.LOAN,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BILLPAY,
  FEATURES.TRANSACTION_BILLPAY_UG,
  FEATURES.TRANSACTION_CASH_IN,
  FEATURES.TRANSACTION_CASH_OUT,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TELCO,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
  FEATURES.WALLET,
];
const oparetaStagingFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPARE,
  FEATURES.AGENT_EXPENSE,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_PIN,
  FEATURES.AGENT_VERIFICATION,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_EXPENSE,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.FLOAT_EXCHANGE,
  FEATURES.LOAN,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BILLPAY,
  FEATURES.TRANSACTION_BILLPAY_UG,
  FEATURES.TRANSACTION_CASH_IN,
  FEATURES.TRANSACTION_CASH_OUT,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TELCO,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
  FEATURES.WALLET,
];
const afrimoneyAngolaFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPLIANCE,
  FEATURES.AGENT_CONFIGURATION,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_EXTERNAL_ID,
  FEATURES.AGENT_KNOWLEDGE_CENTER,
  FEATURES.AGENT_SHOW_ACTIVE,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FLOAT_CONTROL,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BUNDLE,
  FEATURES.TRANSACTION_CASH_IN,
  FEATURES.TRANSACTION_CASH_OUT,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
];
const afrimoneyAngolaStagingFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPLIANCE,
  FEATURES.AGENT_CONFIGURATION,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_EXTERNAL_ID,
  FEATURES.AGENT_KNOWLEDGE_CENTER,
  FEATURES.AGENT_SHOW_ACTIVE,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FLOAT_CONTROL,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BUNDLE,
  FEATURES.TRANSACTION_CASH_IN,
  FEATURES.TRANSACTION_CASH_OUT,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
];
const afrimoneyGambiaFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPLIANCE,
  FEATURES.AGENT_CONFIGURATION,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_EXTERNAL_ID,
  FEATURES.AGENT_KNOWLEDGE_CENTER,
  FEATURES.AGENT_SHOW_ACTIVE,
  FEATURES.CUSTOMER_REGISTRATION,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_CASH_IN,
  FEATURES.TRANSACTION_CASH_OUT,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
];
const afrimoneyGambiaStagingFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPLIANCE,
  FEATURES.AGENT_CONFIGURATION,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_EXTERNAL_ID,
  FEATURES.AGENT_KNOWLEDGE_CENTER,
  FEATURES.AGENT_SHOW_ACTIVE,
  FEATURES.CUSTOMER_REGISTRATION,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_CASH_IN,
  FEATURES.TRANSACTION_CASH_OUT,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
];
const cyadDrcStagingFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_EXPENSE,
  FEATURES.AGENT_PIN,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BUNDLE,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TELCO,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
];
const cyadDrcFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_EXPENSE,
  FEATURES.AGENT_PIN,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BUNDLE,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TELCO,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
];
const mobicomZambiaStagingFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPARE,
  FEATURES.AGENT_COMPLIANCE,
  FEATURES.AGENT_CONFIGURATION,
  FEATURES.AGENT_EXPENSE,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_PIN,
  FEATURES.AGENT_TRADING_NAME,
  FEATURES.AGENT_VERIFICATION,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_EXPENSE,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.FLOAT_EXCHANGE,
  FEATURES.LOAN,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BUNDLE,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TELCO,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
];
const mobicomZambiaFeatures = [
  FEATURES.AGENT,
  FEATURES.AGENT_COMPARE,
  FEATURES.AGENT_COMPLIANCE,
  FEATURES.AGENT_CONFIGURATION,
  FEATURES.AGENT_EXPENSE,
  FEATURES.AGENT_EXPERIMENTATION,
  FEATURES.AGENT_PIN,
  FEATURES.AGENT_TRADING_NAME,
  FEATURES.AGENT_VERIFICATION,
  FEATURES.FIELD_AGENT,
  FEATURES.FIELD_AGENT_COMMISSION,
  FEATURES.FIELD_AGENT_EXPENSE,
  FEATURES.FIELD_AGENT_TERRITORY,
  FEATURES.FLOAT_CONTROL,
  FEATURES.FLOAT_EXCHANGE,
  FEATURES.LOAN,
  FEATURES.NOTIFICATION_CENTER,
  FEATURES.REPORT,
  FEATURES.TRANSACTION,
  FEATURES.TRANSACTION_AIRTIME,
  FEATURES.TRANSACTION_BUNDLE,
  FEATURES.TRANSACTION_EXTERNAL_ID,
  FEATURES.TRANSACTION_TELCO,
  FEATURES.TRANSACTION_TRANSFER,
  FEATURES.USER,
];

export const getFeatures = (env) => {
  env = env ?? import.meta.env?.VITE_ENV;
  switch (env) {
    case 'staging_afrimoney_angola':
      return afrimoneyAngolaStagingFeatures;
    case 'staging_afrimoney_gambia':
      return afrimoneyGambiaStagingFeatures;
    case 'staging_mobicom_zambia':
      return mobicomZambiaStagingFeatures;
    case 'staging_cyad_drc':
      return cyadDrcStagingFeatures;
    case 'staging_opareta':
      return oparetaStagingFeatures;
    case 'production_afrimoney_angola':
      return afrimoneyAngolaFeatures;
    case 'production_afrimoney_gambia':
      return afrimoneyGambiaFeatures;
    case 'production_cyad_drc':
      return cyadDrcFeatures;
    case 'production_mobicom_zambia':
      return mobicomZambiaFeatures;
    case 'production_opareta':
    default:
      return oparetaFeatures;
  }
};

export const hasFeature = (feature) =>
  !feature || getFeatures().indexOf(feature) > -1;
