import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { AppBar, Error } from '@/common';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import { FormLabel, ListItem, Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  agentsFetch,
  agentsSearch,
  clearState,
  fieldAgentsFetch,
  fieldAgentsSearch,
  getAgentBalanceAmount,
  reconciliationAgentBalancesFetch,
  setLoadingAgent,
  submitForm,
  updateAttachmentUrls,
  updatePoliceReport,
  uploadAttachmentsFile,
} from '@/float-cash/varianceFormSlice';
import { debounce } from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { amountToNumberOrZero } from '@/float-cash/util';
import { captureEvent } from '@/firebase';

const AgentSubsection = (props) => {
  const { agents, agent, setAgent, isLoadingAgent } = props;
  const [agentNameInputValue, setAgentNameInputValue] = useState('');
  const dispatch = useDispatch();
  const handleSearch = useCallback(
    debounce((value) => dispatch(agentsSearch(value)), 1000),
    []
  );
  useEffect(() => {
    dispatch(setLoadingAgent(true));
    // noinspection JSCheckFunctionSignatures
    handleSearch(agentNameInputValue);
  }, [handleSearch, agentNameInputValue]);

  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <Autocomplete
        getOptionLabel={(agent) =>
          typeof agent == 'string' ? agent : agent?.agentName
        }
        filterOptions={(x) => x}
        options={agents}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={agent}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        onChange={(event, newValue) => {
          setAgent(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setAgentNameInputValue(newInputValue);
        }}
        renderOption={(props, agent) => {
          return (
            <li {...props} key={agent.uuid}>
              {agent.agentName} -{' '}
              {agent.simNumbers?.map((v) => v.phoneNumber).join(',')}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Agent"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isLoadingAgent ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};

const TDRSubSection = (props) => {
  const {
    amount,
    fieldAgents,
    agents,
    setAgents,
    selectedAgents,
    setSelectedAgents,
    fieldAgent,
    setFieldAgent,
    isLoadingAgent,
    totalAmount,
    setTotalAmount,
  } = props;
  const dispatch = useDispatch();
  const [tdrNameInputValue, setTdrNameInputValue] = useState('');
  const handleSearch = useCallback(
    debounce((value) => dispatch(fieldAgentsSearch(value)), 1000),
    []
  );

  const handleFieldAgentSelect = (fieldAgent) => {
    let fieldAgentAccountId = fieldAgent.uuid;
    setFieldAgent(fieldAgent);
    setSelectedAgents([]);
    setAgents([]);
    dispatch(
      reconciliationAgentBalancesFetch(
        new URLSearchParams(`fieldAgentAccountUuid=${fieldAgentAccountId}`)
      )
    );
  };

  const handleAgentBalanceChange = (agentAccountUuid) => (e) => {
    let amount = e.target.value;
    if (isNaN(Number(amount)))
      return console.warn(`>>> ${amount} is not a number`);
    let updatedAgents = selectedAgents.map((value) => {
      if (value.agentAccountUuid !== agentAccountUuid) {
        return value;
      }
      return Object.assign({ ...value }, { balance: amount });
    });
    setSelectedAgents(updatedAgents);
    let total = updatedAgents.reduce((total, current) => {
      total += amountToNumberOrZero(current.balance);
      return total;
    }, 0);
    setTotalAmount(total);
  };

  useEffect(() => {
    dispatch(setLoadingAgent(true));
    // noinspection JSCheckFunctionSignatures
    handleSearch(tdrNameInputValue);
  }, [handleSearch, tdrNameInputValue]);
  return (
    <Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Autocomplete
          getOptionLabel={(fieldAgent) =>
            typeof fieldAgent == 'string' ? fieldAgent : fieldAgent?.name
          }
          filterOptions={(x) => x}
          options={fieldAgents}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={fieldAgent}
          isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
          onChange={(event, newValue) => {
            handleFieldAgentSelect(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setTdrNameInputValue(newInputValue);
          }}
          renderOption={(props, fieldAgent) => {
            return (
              <li {...props} key={fieldAgent.uuid}>
                {fieldAgent.name} - {fieldAgent.phoneNumber}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="TDR"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isLoadingAgent ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mt: 3 }}>
        <Autocomplete
          multiple
          fullWidth
          id="field-agent-agents"
          value={selectedAgents}
          options={agents}
          getOptionLabel={(option) => option.agentName}
          onChange={(event, newValue) => setSelectedAgents(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Agent(s) Involved (Optional)" />
          )}
        />
      </FormControl>

      {selectedAgents.length > 0 ? (
        <div>
          <Typography sx={{ mt: 3 }}>Allocate amounts to agents</Typography>
          <List>
            {selectedAgents.map((agent) => {
              return (
                <ListItem sx={{ pl: 0, pr: 0 }}>
                  <Typography>{agent.agentName}: </Typography>{' '}
                  <TextField
                    placeholder={agent.balance}
                    size="small"
                    onChange={handleAgentBalanceChange(agent.agentAccountUuid)}
                  />
                </ListItem>
              );
            })}
            <ListItem></ListItem>
          </List>

          <Typography
            fontWeight="bold"
            color={
              totalAmount === amountToNumberOrZero(amount) ? 'black' : 'red'
            }
          >
            Total {totalAmount}{' '}
          </Typography>
        </div>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
};

export const VarianceForm = ({ embedded }) => {
  const inputStyle = { padding: '8px 12px' };
  const dispatch = useDispatch();
  const [fieldAgent, setFieldAgent] = useState(null);
  const [agent, setAgent] = useState(null);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [agentBalancesList, setAgentBalancesList] = useState([]);
  const [comment, setComment] = useState('');
  const [attachmentType, setAttachmentType] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const currencyCode = import.meta.env?.VITE_CURRENCY_CODE || 'UGX';

  let initialFormState = {
    amount: 0,
    currency: currencyCode,
    comments: [],
    varianceType: 'debit',
    attachments: [],
    extraInfo: {},
    agentType: 'fieldAgent',
  };
  const {
    fieldAgents,
    agents,
    policeReport,
    agentBalances,
    attachmentUrls,
    isSubmitting,
    isLoadingAgent,
    isUploadingFiles,
    error,
    clearForm,
  } = useSelector((state) => {
    return state.varianceForm;
  });
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    dispatch(fieldAgentsFetch());
  }, []);

  useEffect(() => {
    setAgentBalancesList(agentBalances);
  }, [dispatch, agentBalances]);

  useEffect(() => {
    if (!selectedFile) return;
    const fileFormData = new FormData();
    fileFormData.append('file', selectedFile);
    dispatch(uploadAttachmentsFile(fileFormData, attachmentType));
  }, [selectedFile]);

  useEffect(() => {
    setCanSubmit(
      amountToNumberOrZero(formData.amount) > 0 &&
        attachmentUrls.length > 0 &&
        ((formData.agentType === 'fieldAgent' && !!fieldAgent) ||
          (formData.agentType === 'agent' && !!agent))
    );
  }, [formData, fieldAgent, agent, comment, attachmentUrls, policeReport]);

  useEffect(() => {
    if (clearForm === true) {
      resetForm();
    }
  }, [clearForm]);

  const handleOnChange = (title) => (e) => {
    let value = e.target.value;
    if (title === 'comment') {
      setComment(value);
    } else if (title === 'agentType') {
      setAgentBalancesList([]);
      setFormData({ ...formData, [title]: value });
    } else {
      setFormData({ ...formData, [title]: value });
    }
  };

  const handleRemoveAttachment = (url) => {
    console.log('>>> Removing attachment: ', url);
    if (window.confirm('Are you sure you want to remove this attachment?')) {
      const newAttachmentUrls = []
        .concat(...attachmentUrls)
        .filter((value) => value !== url);
      console.log('>>> Proceeding to remove attachments: ', newAttachmentUrls);
      dispatch(updateAttachmentUrls({ attachmentUrls: newAttachmentUrls }));
    }
  };

  const handleRemovePoliceReport = () => {
    console.log('>>> Removing police report: ');
    if (window.confirm('Are you sure you want to remove this police report?')) {
      dispatch(updatePoliceReport({ policeReport: null }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target?.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = () => {
    let _fieldAgent =
      formData.agentType === 'fieldAgent'
        ? fieldAgent?.uuid
        : agent?.fieldAgent?.uuid;
    formData.attachments = attachmentUrls.filter((value) => !!value);
    if (isNaN(Number(formData.amount))) {
      return;
    }
    formData.amount = Number(formData.amount);
    if (comment.length > 0) formData.comments = [comment];
    formData.fieldAgentUuid = _fieldAgent;
    if (formData.agentType === 'fieldAgent') {
      formData.agents = selectedAgents.map((agent) => {
        return {
          agent: {
            uuid: agent.agentAccountUuid,
          },
          amount: amountToNumberOrZero(agent.balance),
          currency: currencyCode,
        };
      });
    } else if (formData.agentType === 'agent') {
      formData.agents = [
        {
          agent: {
            uuid: agent.uuid,
          },
          amount: amountToNumberOrZero(formData.amount),
          currency: currencyCode,
        },
      ];
    }
    formData.extraInfo = {
      source: 'dashboard',
    };
    if (!!policeReport) {
      formData.extraInfo.policeReport = policeReport;
    }
    if (
      selectedAgents.length > 0 &&
      amountToNumberOrZero(formData.amount) !==
        amountToNumberOrZero(totalAmount)
    ) {
      if (
        window.confirm(
          'Allocated amount does not match total amount. Are you sure you want to proceed?'
        )
      ) {
        proceedToSubmit();
      }
    } else {
      proceedToSubmit();
    }
  };

  const proceedToSubmit = () => {
    captureEvent('VARIANCE_FORM_SAVE', {});
    let _formData = formData;
    delete _formData.agentType;
    dispatch(submitForm(_formData));
  };

  const resetForm = () => {
    dispatch(clearState());
    setAgent(null);
    setFieldAgent(null);
    setSelectedAgents([]);
    setSelectedFile(null);
    setAgentBalancesList([]);
    setAttachmentType(null);
    setCanSubmit(false);
    setTotalAmount(0);
    setComment('');
    initialFormState.amount = 0;
    initialFormState.comments = [];
    initialFormState.extraInfo = {};
  };

  return (
    <>
      {error && <Error error={error} onRetry={() => handleSubmit()} />}

      {!embedded && <AppBar>Add a transaction to account for variance</AppBar>}

      <Card variant="outlined" sx={{ borderRadius: 3 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ p: 4 }}>
              <FormControl>
                <FormLabel id="transaction-types-buttons-group-label"></FormLabel>
                <RadioGroup
                  row
                  defaultValue="debit"
                  name="transaction-types-group"
                  onChange={handleOnChange('varianceType')}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Debit"
                    value="debit"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Credit"
                    value="credit"
                  />
                </RadioGroup>
              </FormControl>
              <Box my={1}>
                <Typography mb={1}>Amount</Typography>
                <TextField
                  inputProps={{ style: inputStyle }}
                  sx={{ width: '100%' }}
                  type="number"
                  placeholder="0"
                  onChange={handleOnChange('amount')}
                />
              </Box>
              <FormControl sx={{ mt: 2 }}>
                <FormLabel id="agent-types-buttons-group-label"></FormLabel>
                <RadioGroup
                  row
                  defaultValue="fieldAgent"
                  name="agent-types-group"
                  onChange={handleOnChange('agentType')}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="TDR"
                    value="fieldAgent"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Individual Agent"
                    value="agent"
                  />
                </RadioGroup>
              </FormControl>
              <Box sx={{ mt: 2 }}>
                {formData.agentType === 'fieldAgent' ? (
                  <TDRSubSection
                    amount={formData.amount}
                    totalAmount={totalAmount}
                    setTotalAmount={setTotalAmount}
                    fieldAgents={fieldAgents}
                    fieldAgent={fieldAgent}
                    setFieldAgent={setFieldAgent}
                    isLoadingAgent={isLoadingAgent}
                    agents={agentBalancesList}
                    setAgents={setAgentBalancesList}
                    formData={formData}
                    setFormData={setFormData}
                    selectedAgents={selectedAgents}
                    setSelectedAgents={setSelectedAgents}
                  />
                ) : (
                  <AgentSubsection
                    agent={agent}
                    agents={agents}
                    setAgent={setAgent}
                    isLoadingAgent={isLoadingAgent}
                  />
                )}
              </Box>

              <input
                type="file"
                id="file_input"
                hidden
                onChange={handleFileChange}
              />

              <Stack direction="column">
                <Button
                  variant="contained"
                  disabled={isUploadingFiles}
                  sx={{ mt: 3 }}
                  onClick={() => {
                    setAttachmentType('police_report');
                    document.getElementById('file_input').click();
                  }}
                >
                  Upload police report
                </Button>

                <Button
                  variant="contained"
                  sx={{ mt: 3 }}
                  disabled={isUploadingFiles}
                  onClick={() => {
                    setAttachmentType('attachment');
                    document.getElementById('file_input').click();
                  }}
                >
                  Upload attachment*
                </Button>

                <Stack direction="row" overflow="auto">
                  {!!policeReport ? (
                    <Stack direction="column" flex alignItems="center" p={3}>
                      <img
                        src={policeReport}
                        height="120px"
                        width="120px"
                        style={{ objectFit: 'cover' }}
                        alt=""
                      />
                      <Typography sx={{ mt: 1 }}>Police Report</Typography>
                      <Button
                        variant="text"
                        onClick={() => handleRemovePoliceReport()}
                      >
                        Remove
                      </Button>
                    </Stack>
                  ) : (
                    <></>
                  )}
                  {attachmentUrls.map((attachment, index) => {
                    return (
                      <Stack direction="column" flex alignItems="center" p={3}>
                        <img
                          src={attachment}
                          height="120px"
                          width="120px"
                          style={{ objectFit: 'cover' }}
                          alt=""
                        />
                        <Typography sx={{ mt: 1 }}>
                          Attachment {index + 1}
                        </Typography>
                        <Button
                          variant="text"
                          onClick={() => handleRemoveAttachment(attachment)}
                        >
                          Remove
                        </Button>
                      </Stack>
                    );
                  })}
                </Stack>

                {/*{formData.attachments.length > 0 ? (*/}
                {/*  <Grid container spacing={2}>*/}
                {/*    */}
                {/*  </Grid>*/}
                {/*) : (*/}
                {/*  <></>*/}
                {/*)}*/}
              </Stack>

              <TextField
                autoFocus
                margin="dense"
                id="comments"
                label="Comments"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                minRows={3}
                sx={{ mt: 3 }}
                value={comment}
                onChange={handleOnChange('comment')}
              />

              <Button
                variant="contained"
                sx={{ mt: 3, minWidth: '130px' }}
                disabled={!canSubmit || isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <span>Save</span>{' '}
                {isSubmitting ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <></>
                )}
              </Button>
            </Grid>
            {!!fieldAgent && agentBalancesList.length > 0 ? (
              <Grid item xs={6} sx={{ p: 4 }}>
                <Typography fontWeight="bold">
                  Outstanding agent balances under {fieldAgent.name}
                </Typography>
                {
                  <List>
                    {agentBalancesList.map((agent) => {
                      return (
                        <ListItem sx={{ pl: 0, pr: 0 }}>
                          <Typography>
                            <Stack direction="row">
                              {agent.agentName}:{' '}
                              {agent.currency || currencyCode} {agent.balance}
                            </Stack>
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                }
                <Typography>
                  Total: {currencyCode}
                  {getAgentBalanceAmount(
                    agentBalancesList.reduce((total, current) => {
                      total += Number(current.balance || 0);
                      return total;
                    }, 0)
                  )}
                </Typography>
              </Grid>
            ) : (
              <Box></Box>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
