export const getRoles = () => [
  'AGENT_MANAGER',
  'AGENT',
  'CASH_COLLECTOR',
  'CASH_DISTRIBUTOR',
  'COMPLIANCE_MANAGER',
  'CUSTOMER_ACTIVATION_CREATOR',
  'CUSTOMER_ACTIVATION_MANAGER',
  'CUSTOMER_MANAGER',
  'CUSTOMER_VIEWER',
  'DASHBOARD_MANAGER',
  'FIELD_AGENT_MANAGER',
  'FIELD_AGENT_SUPPORT',
  'FIELD_AGENT_VIEWER',
  'FIELD_AGENT',
  'FLOAT_DISTRIBUTOR',
  'FLOAT_COORDINATOR',
  'LOAN_MANAGER',
  'LOAN_REVIEWER',
  'LOAN_VIEWER',
  'NOTIFICATION_MANAGER',
  'RECONCILIATION_ADMIN',
  'RECONCILIATION_VIEWER',
  'TRANSACTION_MANAGER',
  'USER_MANAGER',
  'WALLET_MANAGER',
];

export const getRoleDefinition = (role) => {
  switch (role) {
    case 'AGENT_MANAGER':
      return [
        'Can manage experimental flags',
        'Can verify agents',
        'Can reset Opareta PIN',
        'Can manage agent knowledge center',
        'Can manage agent configurations',
      ];
    case 'AGENT':
      return [
        'Can perform transactions',
        'Can view personal transaction history',
      ];
    case 'CASH_COLLECTOR':
      return [
        'Can issue cash to agent',
        'Can collect cash from agent',
        'Can view cash tracking book',
      ];
    case 'CASH_DISTRIBUTOR':
      return ['Can advance cash'];
    case 'COMPLIANCE_MANAGER':
      return [
        'Can request agent to update KYA',
        'Can approve or reject KYA submissions',
      ];
    case 'CUSTOMER_ACTIVATION_CREATOR':
      return [
        'Can add customer activations',
        'Can view their customer activations',
      ];
    case 'CUSTOMER_ACTIVATION_MANAGER':
      return [
        'Can update customer activation status & commission',
        'Can delete customer activation',
      ];
    case 'CUSTOMER_MANAGER':
      return [
        'Can view customer KYC',
        'Can manage customer contract',
        'Can manage customer compliance',
      ];
    case 'CUSTOMER_VIEWER':
      return ['Can view customer KYC'];
    case 'DASHBOARD_MANAGER':
      return ['Can modify dashboard configurarions'];
    case 'FIELD_AGENT_MANAGER':
      return [
        'Can assign field agent',
        'Can manage field agents',
        'Can manage field agent positions',
        'Can manage field agent territories',
        'Can manage field agent expenses',
      ];
    case 'FIELD_AGENT_SUPPORT':
      return ['Can add agents'];
    case 'FIELD_AGENT_VIEWER':
      return [
        'Can view field agents',
        'Can view field agent positions',
        'Can view field agent territories',
        'Can view field agent expenses',
      ];
    case 'FIELD_AGENT':
      return [
        'Can view their agents',
        "Can view their agent's transactions",
        'Can view their territories',
      ];
    case 'FLOAT_COORDINATOR':
      return ['Can assign float request', 'Can reassign float request'];
    case 'FLOAT_DISTRIBUTOR':
      return [
        'Can make float request for agent',
        'Can review float request from agent',
      ];
    case 'LOAN_MANAGER':
      return [
        'Manage loan rules',
        'Manage loan contracts',
        'Manage loan products',
        'Manage loan duration',
      ];
    case 'LOAN_REVIEWER':
      return ['Review loan requests', 'Disburse loan requests'];
    case 'LOAN_VIEWER':
      return [
        'View loan payments',
        'Make loan offer',
        'View loan disbursements',
        'View loan rules',
        'View loan contracts',
        'View loan products',
        'View loan duration',
      ];
    case 'NOTIFICATION_MANAGER':
      return ['Can send notifications'];
    case 'RECONCILIATION_ADMIN':
      return [
        'Can review float & cash requests',
        'Can disburse float & cash requests',
        'Can manage float & cash reconciliation',
        'Can manage float & cash variances',
        'Can manage float & cash thresholds',
        'Can configure float reconciliation',
      ];
    case 'RECONCILIATION_VIEWER':
      return [
        'Can view float & cash requests',
        'Can view float & cash disbursements',
        'Can view float & cash variances',
        'Can view float & cash thresholds',
        'Can view float reconciliations',
      ];
    case 'TRANSACTION_MANAGER':
      return [
        'Manage float exchanges',
        'Manage USSD actions & parsers',
        'View transactions',
      ];
    case 'USER_MANAGER':
      return ['Manage users'];
    case 'WALLET_MANAGER':
      return ['Manage wallets', 'Manage transaction requests'];
    default:
      return [role];
  }
};
