import * as Sentry from '@sentry/react';

Sentry.init({
  environment:
    import.meta.env?.VITE_ENV ?? import.meta.env?.VITE_API_URL ?? 'production',
  dsn: 'https://e7ddf787ac6c1e8f1be63d40a8b98f5a@o4507764408123392.ingest.us.sentry.io/4507764431060992',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.25, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/.*?.opareta\.com\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: 'opareta-crm@1.4.10',
});
