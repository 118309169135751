import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { AppBar, TabPanel, useFilters } from '@/common';
import Tabs from '@mui/material/Tabs';
import { FEATURES, hasFeature } from '@/common/features';
import Tab from '@mui/material/Tab';
import { a11yProps } from '@/common/TabPanel';
import { ExpenseList } from '@/expense';
import { captureEvent } from '@/firebase';
import { hasAnyRoles } from '@/auth/helpers';
import { useSelector } from 'react-redux';
import { DashboardConfiguration } from '@/dashboardConfiguration';
import { ModuleConfiguration } from '@/moduleConfiguration/ModuleConfiguration';
import { useEffect, useState } from 'react';
import { ModuleSettings } from '@/settings/ModuleSettings';
import { CustomerContractForm } from '@/customer/CustomerContractForm';
import { AgentConfiguration } from '@/agent';

export const Settings = ({ embedded }) => {
  const { user } = useSelector((state) => state.auth);
  const [filters] = useFilters();
  const [tab, setTab] = useState(0);
  useEffect(() => {
    const _tab = Number(filters.get('tab'));
    if (!isNaN(_tab)) setTab(_tab);
  }, []);
  const handleTabChange = (event, index) => {
    setTab(index);
  };
  return (
    <>
      <AppBar>Settings</AppBar>

      <>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={{ background: 'white' }}
          aria-label="field agent detail tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {hasAnyRoles(user.role, 'DASHBOARD_MANAGER') && (
            <Tab label="Dashboard Configuration" value={0} {...a11yProps(0)} />
          )}
          {hasAnyRoles(
            user.role,
            'RECONCILIATION_ADMIN,RECONCILIATION_VIEWER'
          ) && (
            <Tab
              label="Float Control Configuration"
              value={1}
              {...a11yProps(1)}
            ></Tab>
          )}
          {hasAnyRoles(user.role, 'USER_MANAGER') && (
            <Tab label="User Configuration" value={2} {...a11yProps(2)}></Tab>
          )}
          {hasAnyRoles(user.role, 'CUSTOMER_MANAGER') && (
            <Tab
              label="Customer Configuration"
              value={3}
              {...a11yProps(3)}
            ></Tab>
          )}
          {hasAnyRoles(user.role, 'AGENT_MANAGER') && (
            <Tab label="Agent Configuration" value={4} {...a11yProps(4)}></Tab>
          )}
        </Tabs>
        <TabPanel value={tab} index={0}>
          <DashboardConfiguration />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ModuleSettings
            embedded={{
              description: 'Settings for float cash management',
              module: 'float_manager',
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <ModuleSettings
            embedded={{
              description: 'Settings for user management',
              module: 'user',
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <CustomerContractForm />
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <AgentConfiguration />
        </TabPanel>
      </>
    </>
  );
};
