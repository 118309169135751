import { hasAnyRoles } from '@/auth/helpers';
import {
  AppBar,
  Error,
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { Grid, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { floatCashListFetch } from './floatCashListSlice';
import {
  getCreatedAt,
  getFloatCashAgentNumber,
  getFloatCashAmount,
  getFloatCashBankName,
  getFloatCashComments,
  getFloatCashConfirmation,
  getFloatCashCurrency,
  getFloatCashFieldAgentName,
  getFloatCashAgentName,
  getFloatCashType,
  getFloatCashTypeText,
  getFloatCashTypes,
  getFloatCashAgentSignature,
  getFloatCashFieldAgentSignature,
  getFloatCashSubmittedBy,
  getFloatCashAgentTDR,
} from './util';
import { CashDepositForm } from '@/float-cash/CashDepositForm';
import { CashCollectionForm } from '@/float-cash/CashCollectionForm';

export const CashList = ({ embedded }) => {
  // console.log('RXD:CashList');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { floatCashList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.floatCashList;
    }
  );
  const { user } = useSelector((state) => state.auth);

  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);
  const [fullScreenUrl, setFullScreenUrl] = useState('');
  const [cashDepositDialogOpen, setCashDepositDialogOpen] = useState(false);
  const [collectCashDialogOpen, setCollectCashDialogOpen] = useState(false);
  const [allowOpenDetail, setAllowOpenDetail] = useState(true);

  const handleClose = () => setFullScreenUrl('');

  const handleCashDepositDialogClose = () => setCashDepositDialogOpen(false);

  const handleCloseCollectCashDialog = () => setCollectCashDialogOpen(false);

  const handleAllowOpenDetail = (allow) => setAllowOpenDetail(allow);

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, ['fa.uuid']);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-createdAt');
    }
    if (!currentFilters.has('transactionType')) {
      currentFilters.set(
        'transactionType',
        `isAnyOf:${getFloatCashTypes().join(',')}`
      );
    }
    if (!currentFilters.has('isVariance')) {
      currentFilters.set('isVariance', false);
    }
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(floatCashListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Transaction Time',
      type: 'dateTime',
      valueGetter: w(getCreatedAt),
      minWidth: 200,
      sortingOrder: getSortOrder(),
    },
    {
      field: 'fa.name',
      headerName: 'Submitted by',
      minWidth: 200,
      valueGetter: w(getFloatCashSubmittedBy),
      type: 'none',
    },
    {
      field: 't.fieldAgentName',
      headerName: 'Field Agent',
      minWidth: 200,
      valueGetter: w(getFloatCashFieldAgentName),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'transactionType',
      headerName: 'Type',
      minWidth: 200,
      valueGetter: w(getFloatCashType),
      sortingOrder: getSortOrder(),
      type: 'singleSelect',
      valueOptions: getFloatCashTypes().map((type) => ({
        label: getFloatCashTypeText(type),
        value: type,
      })),
    },
    {
      field: 'currency',
      headerName: 'Currency',
      minWidth: 200,
      valueGetter: w(getFloatCashCurrency),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 200,
      valueGetter: w(getFloatCashAmount),
      sortingOrder: getSortOrder(),
      type: 'number',
    },
    {
      field: 't.agentName',
      headerName: 'Agent Name',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getFloatCashAgentName),
    },
    {
      field: 'phoneNumber',
      headerName: 'Agent Number',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getFloatCashAgentNumber),
    },
    {
      field: 'comments',
      headerName: 'Comments',
      minWidth: 200,
      valueGetter: w(getFloatCashComments),
    },
    {
      field: 't.bankAccountBankName',
      headerName: 'Bank',
      minWidth: 200,
      valueGetter: w(getFloatCashBankName),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'bankAttachment',
      headerName: 'Deposit Confirmation',
      minWidth: 200,
      sortingOrder: false,
      filter: false,
      renderCell: w((transaction) => {
        return (
          <>
            {getFloatCashConfirmation(transaction).map((src, i) => (
              <Box
                key={i.toString()}
                component="img"
                sx={{ maxWidth: '50px' }}
                src={src}
                alt="Scan Confirmation"
                onMouseEnter={() => handleAllowOpenDetail(false)}
                onMouseLeave={() => handleAllowOpenDetail(true)}
                onClick={(e) => setFullScreenUrl(e.target.src)}
              />
            ))}
          </>
        );
      }),
    },
    {
      field: 'agentSignature',
      headerName: 'Agent Signature',
      minWidth: 200,
      sortingOrder: false,
      filter: false,
      renderCell: w((transaction) => {
        return (
          <>
            {getFloatCashAgentSignature(transaction).map((src) => (
              <Box
                component="img"
                sx={{ maxWidth: '50px' }}
                src={src}
                alt="Agent Signature"
                onClick={(e) => setFullScreenUrl(e.target.src)}
              />
            ))}
          </>
        );
      }),
    },
    {
      field: 'fieldAgentSignature',
      headerName: 'Field Agent Signature',
      minWidth: 200,
      sortingOrder: false,
      filter: false,
      renderCell: w((transaction) => {
        return (
          <>
            {getFloatCashFieldAgentSignature(transaction).map((src) => (
              <Box
                component="img"
                sx={{ maxWidth: '50px' }}
                src={src}
                alt="Field Agent Signature"
                onClick={(e) => setFullScreenUrl(e.target.src)}
              />
            ))}
          </>
        );
      }),
    },
  ];

  return (
    <>
      {!embedded && (
        <AppBar
          actions={
            hasAnyRoles(user.role, 'FLOAT_DISTRIBUTOR') ? (
              <>
                <Button
                  disableElevation
                  variant="contained"
                  onClick={(e) => setCollectCashDialogOpen(true)}
                >
                  Collect cash
                </Button>

                <Button
                  disableElevation
                  variant="contained"
                  component={RouterLink}
                  to="/float-cash/issue-cash"
                >
                  Issue Cash
                </Button>

                <Button
                  disableElevation
                  variant="contained"
                  onClick={(e) => setCashDepositDialogOpen(true)}
                >
                  Add Deposit
                </Button>
              </>
            ) : null
          }
        >
          Cash Transactions
        </AppBar>
      )}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(floatCashListFetch(filters))}
        />
      )}

      <Modal
        open={Boolean(fullScreenUrl)}
        onClose={handleClose}
        onClick={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Grid
          container
          spacing={0}
          direction="column"
          sx={{
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            component="img"
            sx={{ maxWidth: '100%', maxHeight: '100%' }}
            src={fullScreenUrl}
            alt={fullScreenUrl}
          />
        </Grid>
      </Modal>

      <CashDepositForm
        open={cashDepositDialogOpen}
        handleClose={handleCashDepositDialogClose}
      />

      <CashCollectionForm
        open={collectCashDialogOpen}
        handleClose={handleCloseCollectCashDialog}
      />

      <div
      // style={{
      //   height: 'calc(100vh - 190px)',
      //   minHeight: 300,
      // }}
      >
        <OparetaTable
          getRowId={(transaction) => transaction.uuid}
          rows={floatCashList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            if (!allowOpenDetail) return;
            const transaction = param.row;
            if (e.ctrlKey) {
              window.open(`/float-cash/cash/${transaction.uuid}`);
            } else {
              // dispatch(setFloatRequest(transaction));
              navigate(`/float-cash/cash/${transaction.uuid}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('CashList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('CashList', {
                currency: false,
                agentSignature: false,
                fieldAgentSignature: false,
              }),
            },
          }}
        />
      </div>
    </>
  );
};
