import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  clearState,
  fetchBanks,
  fieldAgentsFetch,
  removeUploadFile,
  setLoading,
  setLoadingBanks,
  submitForm,
  uploadAttachmentsFile,
} from '@/float-cash/cashDepositFormSlice';
import { debounce } from 'lodash';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import { amountToNumberOrZero } from '@/float-cash/util';
import { captureEvent } from '@/firebase';

export const CashDepositForm = (props) => {
  const inputStyle = { padding: '8px 12px' };
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const currencyCode = import.meta.env?.VITE_CURRENCY_CODE || 'UGX';
  let initialFormState = {
    transactionType: 'DEPOSIT',
    currency: currencyCode,
    fromAccountId: '',
    toAccountId: '',
    amount: 0,
    extraInfo: {
      source: 'dashboard',
    },
    comments: [],
    attachments: [],
  };
  const [formData, setFormData] = useState(initialFormState);
  const [canSave, setCanSave] = useState(false);
  const [fieldAgent, setFieldAgent] = useState(null);
  const [bank, setBank] = useState(null);
  const [comment, setComment] = useState('');
  const [tdrNameInputValue, setTdrNameInputValue] = useState('');
  const [bankNameInputValue, setBankNameInputValue] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    fieldAgents,
    banks,
    isLoading,
    isLoadingBanks,
    isSubmitting,
    error,
    attachmentUrl,
    closeDepositFormModal,
  } = useSelector((state) => {
    return state.cashDepositForm;
  });

  const handleTDRSearch = useCallback(
    debounce(
      (value) =>
        dispatch(
          fieldAgentsFetch(
            new URLSearchParams(`name=contains:${value}&oo=name&e=1&pp=1:50`)
          )
        ),
      1000
    ),
    []
  );

  const handleBanksSearch = useCallback(
    debounce(
      (value) =>
        dispatch(
          fetchBanks(
            new URLSearchParams(
              `bankName=contains:${value}&oo=name&e=1&pp=1:50`
            )
          )
        ),
      1000
    ),
    []
  );

  useEffect(() => {
    dispatch(fieldAgentsFetch());
    dispatch(fetchBanks());
  }, []);

  useEffect(() => {
    dispatch(setLoading(true));
    // noinspection JSCheckFunctionSignatures
    handleTDRSearch(tdrNameInputValue);
  }, [handleTDRSearch, tdrNameInputValue]);

  useEffect(() => {
    dispatch(setLoadingBanks(true));
    // noinspection JSCheckFunctionSignatures
    handleBanksSearch(bankNameInputValue);
  }, [handleBanksSearch, bankNameInputValue]);

  useEffect(() => {
    console.log('>>> File selected: ', selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    if (!selectedFile) return;
    const fileFormData = new FormData();
    fileFormData.append('file', selectedFile);
    dispatch(uploadAttachmentsFile(fileFormData));
  }, [selectedFile]);

  useEffect(() => {
    setCanSave(
      amountToNumberOrZero(formData.amount) > 0 &&
        !!bank &&
        !!attachmentUrl &&
        (comment?.trim()?.length || 0) > 0
    );
  }, [formData, fieldAgent, attachmentUrl, bank, comment]);

  useEffect(() => {
    console.log(
      '>>> Attempting to close deposit form modal? Close? ',
      closeDepositFormModal
    );
    if (closeDepositFormModal === true) {
      console.log(
        '>>> Closing deposit form modal. Close? ',
        closeDepositFormModal
      );
      handleClose();
    }
  }, [closeDepositFormModal]);

  useEffect(() => {
    console.log(
      `>>> Open changed to ${open}. Attempting to Close modal? ${closeDepositFormModal}`
    );
    if (open === false) {
      console.log(
        `>>> Open changed to ${open}. Close modal? ${closeDepositFormModal}`
      );
      resetForm();
    }
  }, [open]);

  const handleOnChange = (title) => (e) => {
    let value = e.target.value;
    if (title === 'comment') {
      setComment(value);
    } else {
      setFormData({ ...formData, [title]: value });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target?.files[0];
    console.log('>>> Handing file change. File: ', file);
    setSelectedFile(file);
  };

  const handleFieldAgentSelect = (fieldAgent) => {
    setFieldAgent(fieldAgent);
  };

  const handleBankSelect = (bank) => setBank(bank);

  const handleRemoveAttachment = () => {
    dispatch(removeUploadFile());
  };

  const handleSubmit = () => {
    formData.fromAccountId = fieldAgent?.uuid;
    formData.toAccountId = bank?.uuid;
    formData.amount = Number(formData.amount);
    formData.attachments = [attachmentUrl];
    formData.extraInfo = {
      submittedBy: fieldAgent,
    };
    if ((comment?.length || 0) > 0) formData.comments = [comment];
    dispatch(submitForm(formData));

    captureEvent('CASH_DEPOSIT_FORM_SAVE', {});
  };

  const resetForm = () => {
    dispatch(clearState());
    setFieldAgent(null);
    setSelectedFile(null);
    setBank(null);
    setComment('');
    initialFormState.fromAccountId = '';
    initialFormState.toAccountId = '';
    initialFormState.amount = 0;
    initialFormState.extraInfo = {
      source: 'dashboard',
    };
    initialFormState.comments = [];
    initialFormState.attachments = [];
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add cash deposit</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <Autocomplete
              getOptionLabel={(fieldAgent) =>
                typeof fieldAgent == 'string' ? fieldAgent : fieldAgent?.name
              }
              filterOptions={(x) => x}
              options={fieldAgents}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={fieldAgent}
              isOptionEqualToValue={(option, value) =>
                option.uuid === value.uuid
              }
              onChange={(event, newValue) => {
                handleFieldAgentSelect(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setTdrNameInputValue(newInputValue);
              }}
              renderOption={(props, fieldAgent) => {
                return (
                  <li {...props} key={fieldAgent.uuid}>
                    {fieldAgent.name} - {fieldAgent.phoneNumber}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="TDR"
                  placeholder="Search TDRs by name"
                  required
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>

          <input
            type="file"
            id="file-input"
            onChange={handleFileChange}
            hidden
          />

          {attachmentUrl != null ? (
            <Stack
              direction="column"
              flex
              alignItems="center"
              mt={1}
              p={3}
              border={1}
              style={{
                objectFit: 'cover',
                borderRadius: 4,
                color: 'primary',
              }}
            >
              <img
                src={attachmentUrl}
                height="400px"
                alt=""
                style={{ objectFit: 'scale-down' }}
              />
              <Stack direction="row" flex alignItems="center">
                <Typography>Deposit Slip</Typography>
                <Button variant="text" onClick={() => handleRemoveAttachment()}>
                  Remove
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="column">
              <Button
                variant="contained"
                sx={{ mt: 3, width: '55%' }}
                onClick={() => document.getElementById('file-input')?.click()}
              >
                Upload deposit slip*
              </Button>
              <Typography fontWeight="bold">
                Accepted file formats are jpg and png.
              </Typography>
            </Stack>
          )}

          <TextField
            sx={{ width: '100%', mt: 3 }}
            type="number"
            label="Amount"
            required
            onChange={handleOnChange('amount')}
          />

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Autocomplete
              getOptionLabel={(bank) => bank?.bankName}
              filterOptions={(x) => x}
              options={banks}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={bank}
              isOptionEqualToValue={(option, value) =>
                option.uuid === value.uuid
              }
              onChange={(event, newValue) => {
                handleBankSelect(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setBankNameInputValue(newInputValue);
              }}
              renderOption={(props, bank) => {
                return (
                  <li {...props} key={bank.uuid}>
                    {bank.accountHolderName} - {bank.bankName}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Bank"
                  required
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {isLoadingBanks ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="comments"
            label="Comments"
            placeholder="Additional info about transaction"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            minRows={3}
            sx={{ mt: 3 }}
            value={comment}
            required
            onChange={handleOnChange('comment')}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              borderColor: 'gray',
              borderWidth: '1px',
              fontWeight: 'normal',
              color: 'gray',
            }}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            disabled={!canSave || isSubmitting}
            onClick={handleSubmit}
          >
            <span>Save</span>{' '}
            {isSubmitting ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <></>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
