import { agentListFetch } from '@/agent/agentListSlice';
import { AppBar, Error, Loading, useQuery } from '@/common';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loanRequestSave } from './loanRequestFormSlice';
import { getRequestCurrencies, getRequestTermUnits } from './utils';
import { captureEvent } from '@/firebase';

export const LoanRequestForm = () => {
  // console.log('RXD:LoanRequestForm');
  const dispatch = useDispatch();
  const query = useQuery();
  const { uuid } = useParams();
  const { error: formError, status } = useSelector(
    (state) => state.loanRequestForm
  );
  const { error: agentsError, agentList: agents } = useSelector(
    (state) => state.agentList
  );
  const { isLoading } = useSelector((state) => state.loanRequest);

  const [agent, setAgent] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [requestedAmount, setRequestedAmount] = useState('');
  const [requestedTerm, setRequestedTerm] = useState('');
  const [requestedTermUnit, setRequestedTermUnit] = useState('week');
  const [currency, setCurrency] = useState(
    import.meta.env?.VITE_CURRENCY_CODE ?? 'UGX'
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(
      (value) =>
        dispatch(
          agentListFetch(
            new URLSearchParams(
              `agentName=contains:${value}&oo=agentName&e=1&pp=1:10`
            )
          )
        ),
      1000
    ),
    []
  );

  useEffect(() => {
    try {
      const urlAgent = JSON.parse(decodeURI(query.get('agent')));
      // console.log('RXD:LoanRequestForm', 'urlAgent', urlAgent);
      setAgent(urlAgent);
    } catch (err) {
      console.error('RXD:LoanRequestForm', err);
    }
  }, [query]);

  useEffect(() => {
    handleSearch(inputValue);
  }, [handleSearch, inputValue]);

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      currency,
      requestedAmount,
      requestedTerm,
      requestedTermUnit,
      agent,
    };
    dispatch(loanRequestSave(uuid, data));
    captureEvent('LOAN_REQUEST_FORM_SAVE', {});
  };

  // console.log('RXD:LoanRequestForm', 'uuid', uuid, 'status', status);

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <>
      <AppBar>{uuid ? 'Edit Loan Request' : 'Make Loan Offer'}</AppBar>

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <Box
                    component="form"
                    method="post"
                    sx={{ p: 1 }}
                    onSubmit={handleSave}
                  >
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <Autocomplete
                        getOptionLabel={(agent) =>
                          typeof agent == 'string' ? agent : agent?.agentName
                        }
                        filterOptions={(x) => x}
                        options={agents}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={agent}
                        isOptionEqualToValue={(option, value) =>
                          option.uuid === value.uuid
                        }
                        onChange={(event, newValue) => {
                          setAgent(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        renderOption={(props, agent) => {
                          return (
                            <li {...props} key={agent.uuid}>
                              {agent.agentName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Agent"
                            error={!!agentsError}
                            helperText={agentsError}
                            required
                          />
                        )}
                      />
                    </FormControl>

                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <Grid container sx={{ mb: 2 }}>
                          <Grid item xs={2}>
                            <FormControl fullWidth>
                              <Select
                                id="currency"
                                variant="outlined"
                                value={currency}
                                onChange={w(setCurrency)}
                                required
                              >
                                {getRequestCurrencies().map((c) => (
                                  <MenuItem key={c} value={c}>
                                    {c}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={10}>
                            <FormControl fullWidth>
                              <TextField
                                id="requestedAmount"
                                label="Requested Amount"
                                variant="outlined"
                                type="number"
                                min="0"
                                value={requestedAmount}
                                onChange={w(setRequestedAmount)}
                                required
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={6}>
                        <Grid container sx={{ mb: 2 }}>
                          <Grid item xs={10}>
                            <FormControl fullWidth>
                              <TextField
                                id="requestedTerm"
                                label="Requested Term"
                                variant="outlined"
                                type="number"
                                min="0"
                                value={requestedTerm}
                                onChange={w(setRequestedTerm)}
                                required
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl fullWidth>
                              <Select
                                id="requestedTermUnit"
                                variant="outlined"
                                value={requestedTermUnit}
                                onChange={w(setRequestedTermUnit)}
                                required
                              >
                                {getRequestTermUnits().map((unit) => (
                                  <MenuItem key={unit} value={unit}>
                                    {unit}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {status === 'loading' ? (
                      <Loading />
                    ) : (
                      <Button variant="contained" sx={{ mb: 1 }} type="submit">
                        Save
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
