import { agentListFetch } from '@/agent/agentListSlice';
import { AppBar, Error, Loading } from '@/common';
import { Backdrop } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { notificationSave, resetState } from './notificationFormSlice';
import { notificationFetch } from './notificationSlice';
import { getTypeLabel, getTypes } from './utils';
import { captureEvent } from '@/firebase';

export const NotificationForm = () => {
  // console.log('RXD:NotificationForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { user } = useSelector((state) => state.auth);

  const { notification, status, error } = useSelector(
    (state) => state.notification
  );

  const { error: formError, status: formStatus } = useSelector(
    (state) => state.notificationForm
  );

  const {
    error: agentsError,
    isLoading: agentsIsLoading,
    lastFilter: agentsLastFilter,
    agentList,
  } = useSelector((state) => state.agentList);

  const [multiFilter, setMultiFilter] = useState({
    isFetching: false,
    label: '',
    values: [],
  });
  const prevInputValue = useRef('');
  const [title, setTitle] = useState('');
  const [scheduledAt, setScheduledAt] = useState('');
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [agents, setAgents] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // console.log('RXD:NotificationForm', { uuid, notification, status, error });

  useEffect(() => {
    // console.log('RXD: NotificationForm: useEffect() for change');
    if (
      (uuid !== `${notification?.uuid}` ||
        notification?.agents === undefined) &&
      uuid !== 'add' &&
      status !== 'loading' &&
      !error
    ) {
      // console.log('RXD: NotificationForm resetState()');
      dispatch(resetState());
      setTitle('');
      setScheduledAt(new Date().toISOString());
      setType('');
      setMessage('');
      setAgents([]);
      if (uuid) {
        dispatch(notificationFetch(uuid));
      }
    }
  }, [dispatch, notification, uuid, status, error]);

  // initial values
  useEffect(() => {
    if (uuid === `${notification?.uuid}`) {
      // console.log('RXD: NotificationForm initial', { uuid });
      // console.log('RXD: NotificationForm initial', { notification });
      setTitle(notification?.title);
      const d = new Date(notification?.scheduledAt);
      const dateTimeLocalValue = new Date(
        d.getTime() - d.getTimezoneOffset() * 60000
      );
      setScheduledAt(dateTimeLocalValue.toISOString()?.substring(0, 16));
      setType(notification?.type);
      setMessage(notification?.message);
      setAgents(notification?.agents ?? []);
    }
  }, [notification, uuid]);

  useEffect(() => {
    if (
      !agentsError &&
      !agentsIsLoading &&
      (multiFilter.values.length > 0 || multiFilter.isFetching)
    ) {
      if (multiFilter.isFetching) {
        setAgents([...agents, ...agentList]);
        setMultiFilter((v) => ({ ...v, isFetching: false }));
      } else {
        const { label, values } = multiFilter;
        const len = values.length > 200 ? 200 : values.length;
        const params = values.slice(0, len);
        const search = new URLSearchParams(
          `pp=1:${len}&e=1&oo=agentName&ff=a.uuid,a.agentName&` +
            `${label}=isAnyOf:${params.join(',')}`
        );
        setMultiFilter({ isFetching: true, label, values: values.slice(len) });
        dispatch(agentListFetch(search));
      }
    }
  }, [dispatch, agents, multiFilter, agentsIsLoading, agentsError, agentList]);

  useEffect(() => {
    const handleSearch = debounce((value) => {
      const isMultiPhone = /^((\+\d+)(\s*,\s*|))+$/.test(value);
      const isMultiUuid =
        /^((\w{8,8}-\w{4,4}-\w{4,4}-\w{4,4}-\w{12,12})(\s*,\s*|))+$/.test(
          value
        );

      if (isMultiPhone || isMultiUuid) {
        const label = isMultiPhone ? 'phoneNumber' : 'uuid';
        setMultiFilter({
          isFetching: false,
          values: value.split(','),
          label,
        });
      } else {
        const search = new URLSearchParams(
          `pp=1:5&e=1&oo=agentName&ff=a.uuid,a.agentName` +
            `&agentName=contains:${value}`
        );
        dispatch(agentListFetch(search));
      }
    }, 500);

    if (inputValue && prevInputValue.current !== inputValue) {
      // console.log('hnadleSearch');
      prevInputValue.current = inputValue;
      handleSearch(inputValue);
    }
  }, [inputValue, agentsLastFilter, dispatch]);

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      title,
      scheduledAt: new Date(scheduledAt).toISOString(),
      agents,
      type,
      message,
      status: 'pending',
    };
    dispatch(notificationSave(uuid === 'add' ? null : uuid, data));
    captureEvent(
      uuid === 'add' ? 'CREATE_NOTIFICATION' : 'UPDATE_NOTIFICATION',
      { ...data, user }
    );
  };

  const w = (callback) => (e) => callback(e.target.value);

  // console.log('RXD: NotificationForm', { notification });

  return (
    <>
      <AppBar>
        {`Schedule Notification${
          notification?.title ? `: ${notification?.title}` : ''
        }`}
      </AppBar>

      {formError && <Error error={formError} />}

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={multiFilter.isFetching}
      >
        <Loading />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {error ? (
                <Error
                  error={error}
                  onRetry={() => dispatch(notificationFetch(uuid))}
                />
              ) : status === 'loading' ? (
                <Loading />
              ) : (
                <Box
                  component="form"
                  method="POST"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField
                          id="scheduledAt"
                          value={scheduledAt}
                          onChange={w(setScheduledAt)}
                          type="datetime-local"
                          required
                          helperText="Schedule at this time"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="type">Transport Type</InputLabel>
                        <Select
                          id="type"
                          label="Transport Type"
                          variant="outlined"
                          required
                          value={type}
                          onChange={w(setType)}
                        >
                          {getTypes().map((type) => (
                            <MenuItem key={type} value={type}>
                              {getTypeLabel(type)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField
                          id="title"
                          label="Title"
                          variant="outlined"
                          required
                          value={title}
                          onChange={w(setTitle)}
                          helperText={
                            type === 'firebase'
                              ? 'The title of push notification'
                              : type === 'email'
                                ? 'The subject of message'
                                : ''
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      id="message"
                      label="Message"
                      variant="outlined"
                      required
                      value={message}
                      onChange={w(setMessage)}
                      multiline
                      helperText={`The message to send. (Currently ${message.length} characters)`}
                      minRows={5}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Autocomplete
                      getOptionLabel={(agent) =>
                        typeof agent == 'string'
                          ? agent
                          : `${agent?.agentName} (${agent?.uuid})`
                      }
                      filterOptions={(x) => x}
                      options={agentList}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      value={agents}
                      multiple={true}
                      isOptionEqualToValue={(option, value) => {
                        return option.uuid === value.uuid;
                      }}
                      onChange={(_, newValue) => {
                        setAgents(newValue);
                      }}
                      onInputChange={(_, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Agents (${agents?.length ?? 0})`}
                          error={!!agentsError}
                          helperText={
                            agentsError
                              ? agentsError
                              : 'Use a comma separated list of agent UUIDs or phone numbers to bulk add'
                          }
                        />
                      )}
                    />
                  </FormControl>

                  <Divider sx={{ mb: 2 }} />

                  {formStatus === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ mb: 2, textTransform: 'none' }}
                      type="submit"
                    >
                      Save
                    </Button>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
