import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

export const Error = ({
  sx,
  error = 'There was an error processing your request',
  onRetry = null,
}) => (
  <Alert severity="error" sx={sx}>
    <AlertTitle>Error</AlertTitle>
    {error}
    {onRetry ? (
      <Button appearance="primary" onClick={onRetry}>
        Retry
      </Button>
    ) : null}
  </Alert>
);
