import {
  ForgotPassword,
  Login,
  authLoginCheck,
  injectFirebaseApp,
} from '@/auth';
import { AuthorizedPage, LayoutContext, injectStore } from '@/common';
import { CustomerContractForm } from '@/customer/CustomerContractForm';
import {
  FieldAgentAssignForm,
  FieldAgentDetail,
  FieldAgentForm,
  FieldAgentList,
  FieldAgentPositionForm,
  FieldAgentReassignForm,
  FieldAgentTeamForm,
} from '@/fieldAgent';
import { AddCommissionForm, CommissionList } from '@/fieldAgent/commissions';
import { FlagForm, FlagList } from '@/flag';
import {
  AdvanceCashForm,
  CashList,
  DeletedCashList,
  DisbursementList,
  ModuleSettings,
  FloatCashThresholdDetail,
  FloatCashThresholdList,
  FloatCashTransactionDetails,
  FloatCashTransactionEditForm,
  FloatRequestDetail,
  FloatRequestForm,
  FloatRequestList,
  IssueCashForm,
  Reconciliations,
  VarianceForm,
  VarianceList,
  VarianceTransactionDetail,
} from '@/float-cash';
import { NotificationForm, NotificationList } from '@/notification';
import { PushNotificationList } from '@/pushNotification';
import { themeOptions } from '@/theme';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { setUserProperties } from 'firebase/analytics';
import { useEffect, useRef, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import {
  AgentAssignForm,
  AgentCompare,
  AgentConfiguration,
  AgentDetail,
  AgentKnowledgeCenter,
  AgentList,
} from './agent';
import { DebugLogin } from './auth/DebugLogin';
import { Profile } from './auth/Profile';
import { FEATURES } from './common/features';
import { CustomerList } from './customer';
import { CustomerDetail } from './customer/CustomerDetail';
import { DashboardConfiguration } from './dashboardConfiguration';
import { ExpenseDetail, ExpenseForm } from './expense';
import { analytics, captureEvent, firebaseApp } from './firebase';
import { FirebaseContext } from './firebaseContext';
import {
  BulkLoanRepayment,
  LoanContractForm,
  LoanContractList,
  LoanDetail,
  LoanDurationForm,
  LoanDurationList,
  LoanList,
  LoanPaymentList,
  LoanProductForm,
  LoanProductList,
  LoanRepaymentList,
  LoanRequestDetail,
  LoanRequestForm,
  LoanRequestList,
  LoanRuleForm,
  LoanRuleList,
} from './loan';
import { CustomReport, Map, MobileReport } from './report';
import { history, store } from './store';
import {
  ActionForm,
  ActionList,
  FloatExchangeDetail,
  FloatExchangeList,
  ParserForm,
  ParserList,
  TransactionDetail,
  TransactionList,
  TransactionRequestList,
  WalletList,
} from './transaction';
import { UserForm, UserList } from './user';
import { Settings } from '@/settings/Settings';

injectStore(store);
injectFirebaseApp(firebaseApp, store);

console.log({
  VITE_CURRENCY_CODE: import.meta.env.VITE_CURRENCY_CODE,
  VITE_DIALING_CODE: import.meta.env.VITE_DIALING_CODE,
});

const Root = () => {
  return (
    <Routes>
      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.TRANSACTION}
            role="TRANSACTION_MANAGER"
          />
        }
      >
        <Route path="/exchange/:uuid" element={<FloatExchangeDetail />} />
        <Route path="/exchange" element={<FloatExchangeList />} />
      </Route>

      <Route
        element={
          <AuthorizedPage feature={FEATURES.USSD} role="TRANSACTION_MANAGER" />
        }
      >
        <Route path="/ussd/actions/add" element={<ActionForm />} />
        <Route path="/ussd/actions/:id" element={<ActionForm />} />
        <Route path="/ussd/actions" element={<ActionList />} />
        <Route path="/ussd/parsers/add" element={<ParserForm />} />
        <Route path="/ussd/parsers/:id" element={<ParserForm />} />
        <Route path="/ussd/parsers" element={<ParserList />} />
      </Route>

      <Route
        element={
          <AuthorizedPage feature={FEATURES.WALLET} role="WALLET_MANAGER" />
        }
      >
        <Route path="/wallets" element={<WalletList />} />
        <Route
          path="/transactions/requests"
          element={<TransactionRequestList />}
        />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.NOTIFICATION_CENTER}
            role="NOTIFICATION_MANAGER"
          />
        }
      >
        <Route path="/notifications/:uuid" element={<NotificationForm />} />
        <Route path="/notifications/add" element={<NotificationForm />} />
        <Route path="/notifications" element={<NotificationList />} />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.FIELD_AGENT}
            role="FIELD_AGENT_MANAGER,FIELD_AGENT_VIEWER"
          />
        }
      >
        <Route
          path="/field-agents/:uuid/team"
          element={<FieldAgentTeamForm />}
        />
        <Route
          path="/field-agents/commissions/:uuid/edit/"
          element={<AddCommissionForm />}
        />
        <Route path="/field-agents/commissions" element={<CommissionList />} />
        <Route
          path="/field-agents/commissions/add"
          element={<AddCommissionForm />}
        />
        <Route path="/field-agents/:uuid" element={<FieldAgentDetail />} />
        <Route
          path="/field-agents/positions"
          element={<FieldAgentPositionForm />}
        />
        <Route path="/field-agents" element={<FieldAgentList />} />
        <Route path="/field-agents/:uuid/edit" element={<FieldAgentForm />} />
        <Route path="/field-agents/assign" element={<FieldAgentAssignForm />} />
        <Route path="/agents/:uuid/assign" element={<AgentAssignForm />} />
        <Route
          path="/field-agents/:uuid/reassign"
          element={<FieldAgentReassignForm />}
        />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.FIELD_AGENT}
            role="FIELD_AGENT_MANAGER"
          />
        }
      >
        <Route path="/field-agents/add" element={<FieldAgentForm />} />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.FIELD_AGENT_EXPENSE}
            role="FIELD_AGENT_MANAGER"
          />
        }
      >
        <Route
          path="/field-agents/:fieldAgentUuid/expenses/add"
          element={<ExpenseForm mode="field-agents" />}
        />
        <Route
          path="/field-agents/:fieldAgentUuid/expenses/:uuid/form"
          element={<ExpenseForm mode="field-agents" />}
        />
        <Route
          path="/field-agents/:fieldAgentUuid/expenses/:uuid"
          element={<ExpenseDetail mode="field-agents" />}
        />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.AGENT_EXPERIMENTATION}
            role="AGENT_MANAGER"
          />
        }
      >
        <Route path="/flags/add" element={<FlagForm />} />
        <Route path="/flags/:parameter" element={<FlagForm />} />
        <Route path="/flags" element={<FlagList />} />
      </Route>

      <Route
        element={<AuthorizedPage feature={FEATURES.LOAN} role="LOAN_MANAGER" />}
      >
        <Route path="/loans/rules/add" element={<LoanRuleForm />} />
        <Route path="/loans/rules/:uuid/edit" element={<LoanRuleForm />} />
        <Route
          path="/loans/products/:uuid/edit"
          element={<LoanProductForm />}
        />
        <Route path="/loans/products/add" element={<LoanProductForm />} />
        <Route path="/loans/contracts/add" element={<LoanContractForm />} />
        <Route
          path="/loans/contracts/:uuid/edit"
          Component={LoanContractForm}
        />
        <Route path="/loans/durations/add" element={<LoanDurationForm />} />
        <Route
          path="/loans/durations/:id/edit"
          element={<LoanDurationForm />}
        />
      </Route>

      <Route
        element={<AuthorizedPage feature={FEATURES.LOAN} role="LOAN_VIEWER" />}
      >
        <Route path="/loans/contracts" element={<LoanContractList />} />
        <Route path="/loans/durations" element={<LoanDurationList />} />
        <Route path="/loans/payments" element={<LoanPaymentList />} />
        <Route path="/loans/repayments/bulk" element={<BulkLoanRepayment />} />
        <Route path="/loans/repayments" element={<LoanRepaymentList />} />
        <Route path="/loans/products" element={<LoanProductList />} />
        <Route path="/loans/requests/add" element={<LoanRequestForm />} />
        <Route path="/loans/requests/:uuid" element={<LoanRequestDetail />} />
        <Route path="/loans/requests" element={<LoanRequestList />} />
        <Route path="/loans/rules" element={<LoanRuleList />} />
        <Route path="/loans/:uuid" element={<LoanDetail />} />
        <Route path="/loans" element={<LoanList />} />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.FLOAT_CONTROL}
            role="FLOAT_DISTRIBUTOR,RECONCILIATION_VIEWER"
          />
        }
      >
        <Route path="/float-cash/requests/add" element={<FloatRequestForm />} />
        <Route
          path="/float-cash/requests/:uuid"
          element={<FloatRequestDetail />}
        />
        <Route path="/float-cash/requests" element={<FloatRequestList />} />
        <Route path="/float-cash/cash" element={<CashList />} />
        <Route
          path="/float-cash/cash/:uuid"
          element={<FloatCashTransactionDetails />}
        />
        <Route
          path="/float-cash/cash/:uuid/edit"
          element={<FloatCashTransactionEditForm />}
        />
        <Route path="/float-cash/advance-cash" element={<AdvanceCashForm />} />
        <Route path="/float-cash/issue-cash" element={<IssueCashForm />} />
        <Route
          path="/float-cash/reconciliations"
          element={<Reconciliations />}
        />
        <Route path="/float-cash/variances" element={<VarianceList />} />
        <Route path="/float-cash/variances/add" element={<VarianceForm />} />
        <Route
          path="/float-cash/variances/:uuid"
          element={<VarianceTransactionDetail />}
        />

        <Route
          path="/float-cash/disbursements"
          element={<DisbursementList />}
        />
        <Route
          path="/float-cash/thresholds"
          element={<FloatCashThresholdList />}
        />
        <Route
          path="/float-cash/thresholds/:uuid"
          element={<FloatCashThresholdDetail embedded={{ type: 'agent' }} />}
        />
        <Route
          path="/float-cash/field-agent-thresholds/:uuid"
          element={
            <FloatCashThresholdDetail embedded={{ type: 'fieldAgent' }} />
          }
        />
        <Route path="/float-cash/deleted" element={<DeletedCashList />} />
      </Route>
      <Route
        element={<AuthorizedPage feature={FEATURES.LOAN} role="LOAN_VIEWER" />}
      >
        <Route path="/loans/contracts" element={<LoanContractList />} />
        <Route path="/loans/durations" element={<LoanDurationList />} />
        <Route path="/loans/payments" element={<LoanPaymentList />} />
        <Route path="/loans/repayments/bulk" element={<BulkLoanRepayment />} />
        <Route path="/loans/repayments" element={<LoanRepaymentList />} />
        <Route path="/loans/products" element={<LoanProductList />} />
        <Route path="/loans/requests/add" element={<LoanRequestForm />} />
        <Route path="/loans/requests/:uuid" element={<LoanRequestDetail />} />
        <Route path="/loans/requests" element={<LoanRequestList />} />
        <Route path="/loans/rules" element={<LoanRuleList />} />
        <Route path="/loans/:uuid" element={<LoanDetail />} />
        <Route path="/loans" element={<LoanList />} />
      </Route>
      <Route
        element={<AuthorizedPage feature={FEATURES.LOAN} role="LOAN_VIEWER" />}
      >
        <Route path="/loans/contracts" element={<LoanContractList />} />
        <Route path="/loans/durations" element={<LoanDurationList />} />
        <Route path="/loans/payments" element={<LoanPaymentList />} />
        <Route path="/loans/repayments/bulk" element={<BulkLoanRepayment />} />
        <Route path="/loans/repayments" element={<LoanRepaymentList />} />
        <Route path="/loans/products" element={<LoanProductList />} />
        <Route path="/loans/requests/add" element={<LoanRequestForm />} />
        <Route path="/loans/requests/:uuid" element={<LoanRequestDetail />} />
        <Route path="/loans/requests" element={<LoanRequestList />} />
        <Route path="/loans/rules" element={<LoanRuleList />} />
        <Route path="/loans/:uuid" element={<LoanDetail />} />
        <Route path="/loans" element={<LoanList />} />
      </Route>

      <Route
        element={
          <AuthorizedPage feature={FEATURES.AGENT} role="AGENT_MANAGER" />
        }
      >
        <Route path="/agents/compare" element={<AgentCompare />} />
        <Route path="/agents/configuration" element={<AgentConfiguration />} />
        <Route
          path="/agents/knowledge-center"
          element={<AgentKnowledgeCenter />}
        />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.AGENT_EXPENSE}
            role="AGENT_MANAGER"
          />
        }
      >
        <Route
          path="/agents/:agentUuid/expenses/add"
          element={<ExpenseForm mode="agents" />}
        />
        <Route
          path="/agents/:agentUuid/expenses/:uuid"
          element={<ExpenseDetail mode="agents" />}
        />
        <Route
          path="/agents/:agentUuid/expenses/:uuid/form"
          element={<ExpenseForm mode="agents" />}
        />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.TRANSACTION}
            role="AGENT,FIELD_AGENT"
          />
        }
      >
        <Route path="/transactions/:uuid" element={<TransactionDetail />} />
        <Route path="/transactions" element={<TransactionList />} />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.TRANSACTION}
            role="AGENT,FIELD_AGENT,AGENT_MANAGER"
          />
        }
      >
        <Route path="/agents/:uuid" element={<AgentDetail />} />
        <Route path="/agents" element={<AgentList />} />
      </Route>

      <Route
        element={<AuthorizedPage feature={FEATURES.USER} role="USER_MANAGER" />}
      >
        <Route path="/users/add" element={<UserForm />} />
        <Route path="/users/:uuid" element={<UserForm />} />
        <Route path="/users" element={<UserList />} />
      </Route>

      <Route
        element={
          <AuthorizedPage feature={FEATURES.USER} role="CUSTOMER_MANAGER" />
        }
      >
        <Route
          path="/customers/configuration"
          element={<CustomerContractForm />}
        />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.USER}
            role="CUSTOMER_MANAGER,CUSTOMER_VIEWER"
          />
        }
      >
        <Route path="/customers" element={<CustomerList />} />
        <Route
          path="/customers/configuration"
          element={<CustomerContractForm />}
        />
        <Route path="/customers/:uuid" element={<CustomerDetail />} />
      </Route>

      <Route
        element={
          <AuthorizedPage
            feature={FEATURES.REPORT}
            role="AGENT_MANAGER,FIELD_AGENT,FIELD_AGENT_MANAGER"
          />
        }
      >
        <Route path="/maps" element={<Map />} />
      </Route>

      <Route element={<AuthorizedPage feature={FEATURES.REPORT} />}>
        <Route path="/reports/custom/:key" element={<CustomReport />} />
      </Route>

      <Route element={<AuthorizedPage feature="" role="" />}>
        <Route path="/profile" element={<Profile />} />
      </Route>

      <Route element={<AuthorizedPage feature="" role="" />}>
        <Route path="/settings" element={<Settings />} />
      </Route>

      <Route element={<AuthorizedPage feature="" role="DASHBOARD_MANAGER" />}>
        <Route
          path="/dashboard_configuration"
          element={<DashboardConfiguration />}
        />
      </Route>
      <Route
        element={
          <AuthorizedPage
            feature=""
            role="AGENT,FIELD_AGENT,AGENT_MANAGER,FIELD_AGENT_MANAGER,LOAN_REVIEWER,LOAN_MANAGER"
          />
        }
      >
        <Route path="/push_notifications" element={<PushNotificationList />} />
      </Route>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/debug-login/:uuid" element={<DebugLogin />} />
      <Route path="/reports/mobile/:key" element={<MobileReport />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
};

const Layout = () => {
  // console.log('RXD:Layout');
  const dispatch = useDispatch();
  const theme = createTheme(themeOptions);
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const locationRef = useRef();
  const [layout, setLayout] = useState({
    disableGutters: false,
    maxWidth: '2000px',
  });

  useEffect(() => {
    // console.log('RXD:App:dispatch(authLoginCheck())');
    dispatch(authLoginCheck());
  }, [dispatch]);

  useEffect(() => {
    const constructPageName = () => {
      const { pathname } = location;

      const replacements = {
        transaction: 'txn',
        'float-cash': 'float',
        reconciliation: 'recon',
      };

      for (const pattern in replacements) {
        if (pathname.includes(pattern)) {
          return pathname.replace(pattern, replacements[pattern]).toUpperCase();
        }
      }
      return pathname.toUpperCase();
    };

    //log event if previous location is different from new location
    if (locationRef?.current !== location.pathname) {
      locationRef.current = location.pathname;
      captureEvent(`PAGE_VISIT_${constructPageName()}`, {
        url: location.pathname,
        user,
      });
    }
  }, [location]);

  useEffect(() => {
    setUserProperties(analytics, user);
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LayoutContext.Provider value={setLayout}>
        <Container
          sx={{ py: 0 }}
          disableGutters={layout.disableGutters}
          maxWidth={layout.maxWidth}
        >
          <Root />
        </Container>
      </LayoutContext.Provider>
    </ThemeProvider>
  );
};

export const App = () => {
  // console.log('RXD:App');
  return (
    <Provider store={store}>
      <FirebaseContext.Provider value={firebaseApp}>
        <Router history={history}>
          {/*<header>*/}
          {/*    <Notification></Notification>*/}
          {/*</header>*/}
          <Layout />
        </Router>
      </FirebaseContext.Provider>
    </Provider>
  );
};
