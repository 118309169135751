import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';

// Initialize Firebase
export const config = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_DATABASE_URL,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
  measurementId: import.meta.env.VITE_MEASUREMENT_ID,
};
const fcmKeyPair = import.meta.env.VITE_FCM_KEY_PAIR;
// console.log('RXD:', { config });
let _messaging;
export const firebaseApp = initializeApp(config);
try {
  _messaging = getMessaging(firebaseApp);
} catch (error) {
  console.error({ error });
}
export const messaging = _messaging;
export const analytics = getAnalytics(firebaseApp);

export const captureEvent = (eventName = '', data = {}) => {
  // disable logging for localhost
  if (window?.location?.hostname?.includes('localhost')) {
    return;
  }
  const generateEventName = () => {
    return eventName.split(' ').join('_').toUpperCase();
  };
  if (data?.user) {
    setUserProperties(analytics, data?.user || {});
    delete data.user;
  }
  const eventData = {};
  Object.keys(data).forEach((key) => {
    //convert data string since firebase doesn't support values that are non strings like objects and arrays
    if (
      typeof data[key] !== 'string' ||
      typeof data[key] !== 'number' ||
      typeof data[key] !== 'boolean'
    ) {
      eventData[key] = JSON.stringify(data[key]);
    } else {
      eventData[key] = data[key];
    }
  });
  const env = (import.meta?.env?.VITE_ENV || '').toUpperCase();
  console.log(
    'CAPTURING_EVENT',
    `WEB_${env}_${generateEventName()}`,
    eventData
  );
  return logEvent(analytics, `${generateEventName()}_WEB`, {
    ...eventData,
    debug: !env.includes('production'),
    webEnvironment: env,
  });
};

export const logButtonEvents = (eventName = '', data = {}) => {
  const name = `${eventName}_BUTTON_CLICK`;
  return captureEvent(name, data);
};

export const requestPermission = () => {
  console.log('>>> Requesting permission', import.meta.env.VITE_VAPID_KEY);
  return new Promise(async (accept, reject) => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('>>> Notification permission granted');
        return getToken(messaging, {
          vapidKey: import.meta.env.VITE_VAPID_KEY,
        })
          .then((currentToken) => {
            if (currentToken) {
              accept(currentToken);
              console.log('>>> Current token: ', currentToken);
            } else {
              console.log('>>> Failed to generate fcm token');
              reject('Failed to generate fcm token');
            }
          })
          .catch((err) => {
            console.error('>>> Error generating fcm token: ', err);
            reject(err);
          });
      } else {
        console.warn('>>> Notification permission denied');
        reject('Permission denied');
      }
    });
  });
};

if (messaging) {
  onMessage(messaging, (payload) => {
    console.log('>>> Message payload: ', payload);
    let notification = payload?.notification ?? payload?.data?.notification;
    let fcmOption = payload.fcmOptions;
    notification =
      typeof notification === 'string'
        ? JSON.parse(notification)
        : notification;
    console.log('>>> Parsed notification: ', notification);
    const notificationTitle = notification.title;
    const notificationOptions = {
      body: notification.body,
      icon: notification.icon || 'favicon.ico',
      data: {
        link: fcmOption?.link || notification.link || 'push_notifications',
      },
    };
    let webNotification = new Notification(
      notificationTitle,
      notificationOptions
    );
    webNotification.onclick = function (event) {
      event.preventDefault();
      let notification = event.currentTarget || notification;
      console.log('>>> Foreground notification click event: ', notification);
      let url = notification.data.link;
      window.open(url, '_self');
      webNotification.close();
    };
  });
}
// requestPermission();

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//
//   }).catch((err) => console.error(err));

// onMessageListener().then(result => {
//   console.log('>>> New message from listener: ', result)
// })
