import { dateFormat, numberFormat } from '@/common';

const MAX_KYC_STEPS = 15;

export const hasPosition = (agent) => Boolean(agent?.lastLocation);

export const getPosition = (agent) => agent?.lastLocation?.coordinates;

export const formattedCount = (agent) => numberFormat(agent?.transactionCount);

export const formattedVolume = (agent) =>
  numberFormat(agent?.transactionVolume);

export const getBirthday = (agent) =>
  dateFormat(agent?.birthday, 'DD MMM, YYYY') ?? '-';

export const lastActiveAt = (agent) => dateFormat(agent?.lastActiveAt) ?? '-';

export const verifiedAt = (agent) => dateFormat(agent?.verifiedAt) ?? '-';

export const updatedAt = (agent) => dateFormat(agent?.updatedAt);

export const createdAt = (agent) => dateFormat(agent?.createdAt);

export const getFieldAgent = (agent) => agent?.fieldAgent?.name ?? '';

export const getTradingName = (agent) => agent?.tradingName ?? '';

export const getBusinessAddress = (agent) => agent?.location ?? '';

export const getFromDiscovery = (agent) =>
  agent?.additionalData?.createdByAgentDiscoveryForm ?? false;

export const getPhysicalLocation = (agent) =>
  agent?.physicalLocation?.name ?? '';

export const getAgentStatus = (agent) => {
  switch (agent?.contract?.status) {
    case 'completed':
    case 'cleared':
      return 'cleared';
    case 'pre-cleared':
      return 'pre-cleared';
    case 'declined':
      return 'declined';
    case 'refused':
      return 'refused';
    case 'pending':
      return 'pending';
    case 'needs-update':
      return 'needs-update';
    case 'not-started':
    case '':
    case null:
    case undefined:
      return 'not-started';
    default:
      return agent?.contract?.status;
  }
};

export const getAgentStatusBackground = (agent) => {
  switch (agent?.contract?.status) {
    case 'completed':
    case 'cleared':
    case 'pre-cleared':
      return '#21CE99';
    case 'declined':
      return '#ffeeee';
    case 'refused':
      return '#F9595C';
    case 'pending':
    case 'needs-update':
      return '#FDBF39';
    default:
      return '#dddddd';
  }
};

export const getAgentStatusTextColor = (agent) => {
  switch (agent?.contract?.status) {
    case 'completed':
    case 'cleared':
    case 'pre-cleared':
      return '#ffffff';
    case 'declined':
      return '#F9595C';
    case 'refused':
      return '#ffeeee';
    case 'pending':
    case 'needs-update':
      return '#fdeedd';
    default:
      return '#555555';
  }
};

export const getOnboardedFieldAgent = (agent) =>
  agent?.onboardedFieldAgent?.name ?? '';

export const getKyaFieldAgent = (agent) => agent?.kyaFieldAgent?.name ?? null;

export const getKycProgress = (agent) =>
  agent?.step === null
    ? 'Not Started'
    : agent.step === MAX_KYC_STEPS
      ? 'Completed'
      : `${agent.step}/${MAX_KYC_STEPS}`;

export const getKycBackgroundColor = (agent) =>
  agent?.step === null
    ? '#ffcec9'
    : agent.step === MAX_KYC_STEPS
      ? '#DCF2EA'
      : `${agent.step}/${MAX_KYC_STEPS}`;

export const getBusinessTypes = () => [
  'Boutique',
  'Market Stall',
  'Mobile Money Kiosk',
  'Shop',
  'Other',
];

export const getReferredByOptions = () => [
  'agent',
  'field_agent',
  'social',
  'other',
];

export const getSimNumberCategories = () => [
  'CONTACT',
  'UG_AIRTEL',
  'UG_MTN',
  'WHATSAPP',
];

export const getSimCategory = (category) => {
  switch (category) {
    case 'UG_AIRTEL':
    case 'ZM_AIRTEL':
      return 'Airtel Money';
    case 'UG_MTN':
    case 'ZM_MTN':
      return 'MTN Money';
    case 'AO_AFRICELL':
    case 'GM_AFRICELL':
      return 'Afrimoney';
    default:
      return category;
  }
};

export const getAgentStatuses = () => [
  'cleared',
  'declined',
  'needs-update',
  'pending',
  'pre-cleared',
  'refused',
];

export const isReviewableStatus = (agent) => {
  switch (agent?.contract?.status) {
    case 'declined':
    case 'needs-update':
    case 'pending':
    case 'refused':
      return true;
    default:
      return false;
  }
};

export const getContractVariables = () => [
  ['{agent.name}', "The agent's name"],
  ['{agent.address}', "The agent's address"],
  ['{agent.signature}', "The agent's signature"],
  ['{agent.email}', "The agent's email"],
  [
    '{agent.trading_name}',
    'The business name the agent uses in the field and with customers (not always the name registered on the agent line) from agent discovery form',
  ],
  [
    '{agent.landmark}',
    "The nearest landmark to the agent's place of business from agent discovery form",
  ],
  ['{agent.location}', 'The GPS coordinates from agent discovery form'],
  [
    '{agent.owner_name}',
    'The name of the business owner for this agent business; it will be the same as agent.name if this agent is the owner',
  ],
  [
    '{agent.birthdate}',
    'The birth date of the agent, which should be based on the ID details they submitted during KYA. The format can be DD/MM/YYYY',
  ],
  [
    '{agent.id_type}',
    'The name of the id type selected during KYA e.g., NRC, passport. etc',
  ],
  [
    '{agent.passport}',
    'The passport number for this agent. The agent may not have a passport number if they used another form of ID during KYA.',
  ],
  ['{agent.nationality}', 'The nationality of the agent.'],
  [
    '{agent.nrc}',
    'The National Registration Card ID number. The agent may not have a NRC number if they used another form of ID during KYA.',
  ],
  [
    '{agent.driver_license}',
    'The driver license number for this agent. The agent may not have a driver license number if they used another form of ID.',
  ],
  [
    '{agent.ownership}',
    "The agent's ownership status in the business, namely Owner or Handler",
  ],
  ['{agent.national_id}', "The agent's national ID number"],
  [
    '{signature.aggregator}',
    "The signature of the mobile money aggregator (generally Opareta's partner/client)",
  ],
  ['{witness.name}', "The agent's witness' name"],
  ['{witness.signature}', "The agent's witness' signature"],
  ['{today}', "Today's full date e.g. March 12, 2023"],
  ['{today.date}', "Today's date"],
  ['{today.month}', "Today's month"],
  ['{today.year}', "Today's year"],
];
