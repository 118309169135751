import React from 'react';
import { createRoot } from 'react-dom/client';

import './css/index.css';
import { App } from './App';
import * as I from './instrumentation';
// import { register } from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// To work offline and load faster, you can change to register() below
// register();
